import { Container } from "react-bootstrap";
import NotesPageLoggedInView from "../components/NotesPageLoggedInView";
import NotesPageLoggedOutView from "../components/NotesPageLoggedOutView";
import { User } from "../model/user";
import styles from '../styles/NotePage.module.css';

interface NotesPageProps {
    LoggedInUser: User | null;
}

const NotePage = ({ LoggedInUser }: NotesPageProps) => {
    return ( 
        <Container className={styles.notesPage}>
        <>
          {LoggedInUser 
            ? <NotesPageLoggedInView />
            : <NotesPageLoggedOutView />}
        </>
      </Container>
     );
}
 
export default NotePage;