import { useEffect, useState } from 'react';
import LoginModel from './components/LoginModel';
import NavBar from './components/NavBar';
import SignUpModel from './components/SignUpModel';
import { User } from './model/user';
import * as NotesApi from './network/notes_api';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import NotesPage from './pages/NotesPage';
import NotFoundPage from './pages/NotFoundPage';
import PrivacyPage from './pages/PrivacyPage';
import styles from './styles/App.module.css';

function App() {

  const [LoggedInUser, setLoggedInUser] = useState<User|null>(null);

  const [showSignUpModel, setShowSignUpModel] = useState<boolean>(false);
  const [showLoginModel, setShowLoginModel] = useState<boolean>(false);

  useEffect(() => {
    async function fetchLoggedInUser() {
      try {
          const user = await NotesApi.getLoggedInUser();
          setLoggedInUser(user);

      } catch (error) {
        console.log(error);
      }
    }
    fetchLoggedInUser();
}, []);

  return (
    <BrowserRouter>
      <div>
        <NavBar
          loggedInUser={LoggedInUser}
          onLoginClicked={() => setShowLoginModel(true)}
          onSignUpClicked={() => setShowSignUpModel(true)}
          onLogoutSuccessful={() => setLoggedInUser(null)}
          />
          <Container className={styles.pageContainer}>
            <Routes>
              <Route path="/" element={<NotesPage LoggedInUser={LoggedInUser} />} />
              <Route path="/privacy" element={<PrivacyPage />} />
              <Route path="/*" element={<NotFoundPage />} />
            </Routes>

            </Container>
          {
            showSignUpModel && 
              <SignUpModel
                onDismiss={() => setShowSignUpModel(false)}
                onSignUpSuccessful={(user) => {
                  setLoggedInUser(user);
                  setShowSignUpModel(false);
                }}
              />
          }
          {
            showLoginModel &&
              <LoginModel 
                onDismiss={() => setShowLoginModel(false)}
                onLoginSuccessful={(user) => {
                  setLoggedInUser(user);
                  setShowLoginModel(false);
                }}
              />
          }
      </div>
    </BrowserRouter>
  );
}

export default App;
